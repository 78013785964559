import React from 'react'
import { Banner } from '../components/Banner'
import Layout from '../components/Layout'
import { Posts } from '../components/Posts/Posts'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import { Container } from 'react-bootstrap'

const IndexPage = ({ data }) => {
  const {
    allMdx: { nodes: posts },
  } = data
  return (
    <Layout>
      <SEO title="Home" />
      <Container
        style={{
          // marginTop: `50px`,
          fontFamily: `'Open Sans', sans-serif`,
          fontWeight: `300`,
          fontSize: `18px`,
          lineHeight: `.8em`,
        }}
        fluid="lg"
      >
        <section className="banner">
          <Banner />
        </section>
        <Posts posts={posts} postsComponentHeaderTitle="Recent Publications" />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    allMdx(sort: { fields: frontmatter___date, order: DESC }, limit: 3) {
      nodes {
        frontmatter {
          # readTime
          title
          category
          date(formatString: "MMMM Do, YYYY")
          slug
          # image {
          #   childImageSharp {
          #     fluid {
          #       ...GatsbyImageSharpFluid
          #     }
          #   }
          # }
        }
        excerpt
        id
      }
    }
  }
`

export default IndexPage
